<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/>
    <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/>
    <!-- End Header Area -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-area rn-section-gap bg_color--5">
      <div class="contact-form--1">
        <v-container>
          <Contact />
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <Footer />
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../../src/components/header/HeaderOnePageTwoSiber.vue";
  import HeaderOnePageTwoMobile from "../../src/components/header/HeaderOnePageTwoSiberMobile.vue";
  import Contact from "../components/contact/Contact";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderOnePageTwo,
      HeaderOnePageTwoMobile,
      Contact,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Contact Form",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
